import {useNavigate, useParams} from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { useState, useEffect } from "react";
import useAPI from "../../../../../api/api";
import { toast } from "react-toastify";
import fields from "./tblFields.json";
import ListPage from "../../../../../components/shared/ListPage/ListPage";

const DetailChanges = () => {
    const { cId } = useParams();
    const [formFields, setFormFields] = useState(fields);
    const api = useAPI();
    const screen_code = "USERS";
    const [doesRefetch, setDoesRefetch] = useState(false);

    const approveChange = () => {
        return useMutation(["approveChange"], async ({ id, contracts_id, contracts_part }) => {
            return await api
                .post(
                    `admin/contracts/edits/approve`,
                    {
                        id,
                        contracts_id,
                        contracts_part,
                    },
                    "PORTAL_CONTRACTS_EDITS_POST"
                )
                .then((res) => {
                    toast.success("Izmena je uspešno odobrena");
                    setDoesRefetch(true);
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.payload?.message ?? err?.response?.data?.message ?? "Došlo je do greške");
                });
        });
    };

    const declineChange = () => {
        return useMutation(["declineChange"], async ({ id, contracts_id, contracts_part }) => {
            return await api
                .post(
                    `admin/contracts/edits/decline`,
                    {
                        id,
                        contracts_id,
                        contracts_part,
                    },
                    "PORTAL_CONTRACTS_EDITS_POST"
                )
                .then((res) => {
                    toast.success("Izmena je uspešno odbijena");
                    setDoesRefetch(true);
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.payload?.message ?? err?.response?.data?.message ?? "Došlo je do greške");
                });
        });
    };

    const { mutate: approveChangeMutation } = approveChange();
    const { mutate: declineChangeMutation } = declineChange();

    const customActions = {
        edit: {
            icon: "check",
            title: "Odobri",
            label: "Odobri",
            clickHandler: {
                fnc: (rowData) => {
                    console.log(rowData);
                    approveChangeMutation({ id: rowData.id, contracts_id: rowData.contracts_id, contracts_part: rowData.contracts_part });
                },
            },
        },
        delete: {
            icon: "close",
            title: "Odbij",
            label: "Odbij",
            clickHandler: {
                fnc: (rowData) => {
                    declineChangeMutation({ id: rowData.id, contracts_id: rowData.contracts_id, contracts_part: rowData.contracts_part });
                },
            },
        },
    };

    const navigate = useNavigate();

    const buttons = [
        {
            label: "Nazad",
            icon: "arrow_back",
            clickHandler: {
                type: "",
                fnc: () => navigate(-1),
            },
        },
    ];

    return (
        <ListPage
            customActions={customActions}
            screen_code={"PORTAL_CONTRACTS_EDITS_LIST"}
            showNewButton={false}
            additionalButtons={buttons}
            doesRefetch={doesRefetch}
            setDoesRefetch={setDoesRefetch}
            listPageId="DetailChanges"
            apiUrl={`admin/contracts/edits/contract/list`}
            title="Izmene na ugovoru"
            columnFields={formFields}
            contracts_id={cId}
        />
    );
};

export default DetailChanges;
